import React from "react"
import { Section } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import { TextContent } from "../../../components/atoms/TextContent"
import {
  ListCard,
  TripleColumnContainer,
} from "../../../components/atoms/Cards"
import { useIsMobile } from "../../../components/hooks/useDimensions"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { WhoWeWorkWithText } from "../../Top/WhoWeWorkWith"

const TalentsContent = {
  [Languages.EN]:
    "Many recruiting organizations have failed to advance from a ‘tunnel vision’ approach where industry specialists work in separate silos. We’ve assisted many professionals build successful careers within the consulting, financial and corporate sectors while advising our clients on positioning themselves to attract top talent. ",
  [Languages.JP]:
    "業界経験豊富な各キャリアコンサルタントが連携することで、コンサルティング・金融・事業会社の各分野でキャリアを築けるようにサポートしています。その中で、コンサルティング・金融から事業会社へ、またはその逆も実現させています。",
}

const ChangeThePattern = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  const desktop = (
    <span>
      Change <br /> The Pattern
    </span>
  )

  const mobile = <span>Change The Pattern</span>

  const columnText = WhoWeWorkWithText[lang]
  const text = TalentsContent[lang]

  const textContentLeading =
    lang === Languages.JP ? "leading-normal" : "leading-tight sm:leading-snug"

  return (
    <Section classes="px-8">
      <div className="flex flex-col justify-between pl-2 -ml-2 bg-white sm:flex-row sm:-mb-16">
        <Title>{isMobile ? mobile : desktop}</Title>

        <div
          className="pt-4 pb-6 sm:pt-0 sm:px-6 sm:pb-12"
          style={{ width: isMobile ? "100%" : 944 }}
        >
          <TextContent
            fadeIn
            extraClasses={`max-w-2xl ${textContentLeading}`}
            content={text}
          />
          <br className="h-8" />
          <TripleColumnContainer>
            <ListCard
              fadeIn
              title={columnText.consultingFirms.title}
              contentList={columnText.consultingFirms.contentList}
            />
            <ListCard
              fadeIn
              title={columnText.financialInstitutions.title}
              contentList={columnText.financialInstitutions.contentList}
            />
            <ListCard
              fadeIn
              title={columnText.corporateBrands.title}
              contentList={columnText.corporateBrands.contentList}
            />
            {/* <ListCard
              fadeIn
              title={columnText.digitalStartups.title}
              contentList={columnText.digitalStartups.contentList}
              link={{
                label: columnText.digitalStartups.link.label,
                url: columnText.digitalStartups.link.url,
              }}
            /> */}
          </TripleColumnContainer>
        </div>
      </div>
    </Section>
  )
}

export default ChangeThePattern
