import React from "react"
import { Section } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import { useIsMobile } from "../../../components/hooks/useDimensions"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"

const Text = {
  [Languages.EN]: (
    <p className="mt-4 font-serif text-xl leading-tight sm:text-2xl">
      VantagePoint advises not only on your next career move but also considers
      your mid to long-term objectives. Looking at the intersection of your
      skills and experience combined with your personal and professional
      ambitions, creating a trajectory best fitting your goals.
    </p>
  ),
  [Languages.JP]: (
    <p className="mt-4 text-lg tracking-tighter sm:text-xl font-jp-serif">
      バンテージポイントは、単に転職先を紹介するのではなく、候補者様のご経験、ご志向に相応しいキャリアを紹介し、５年先、10年先、20年先に描く理想的なキャリア到達に向けた戦略的なキャリアパスのサポートを行っています。
    </p>
  ),
}

const Copy = () => {
  const lang = useLang()

  return (
    <div className="w-full max-w-3xl text-gray-100">
      <Title>
        <span className="leading-none tracking-normal text-gray-100">
          We Believe No Career Is The Same.
        </span>
      </Title>
      {Text[lang]}
    </div>
  )
}

const HeroText = () => {
  const isMobile = useIsMobile()
  const desktop = (
    <span>
      Change <br /> The Pattern
    </span>
  )

  const mobile = <span>Change The Pattern</span>

  return (
    <Section fullHeight>
      <div
        className="z-10 flex flex-col justify-end pb-24"
        style={{ height: 750, marginTop: -750 }}
      >
        <div className="flex flex-col justify-between pl-2 -ml-2 sm:flex-row">
          {/* this is done in order to match responsive width */}
          <div className="opacity-0">
            <Title>{isMobile ? mobile : desktop}</Title>
          </div>

          <div className="sm:px-6" style={{ width: isMobile ? "100%" : 944 }}>
            <Copy />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroText
