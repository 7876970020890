import React, { useState, useEffect } from "react"
import { Languages } from "../../provider"
import { Job, JobType, jobTypeENJPMapper } from "./types"

export const filterLang = (job: Job, currentLang: Languages) => {
  const jobLang = job.node.frontmatter.lang as Languages

  if (jobLang !== null) return jobLang === currentLang
  return Languages.EN === currentLang
}

export const filterJobType = (job: Job, currentJobType: JobType) => {
  if (currentJobType === JobType.All) return true

  const jobType = job.node.frontmatter.filterType
  if (jobType !== null) return jobType === currentJobType
  return true
}

const translateJobType = (jobType: JobType, lang: Languages) => {
  return lang === Languages.EN
    ? jobType
    : (jobTypeENJPMapper[jobType] as JobType)
}

interface FilterButton {
  jobType: JobType
  current: JobType
  setCurrent: any
  lang: Languages
}

const FilterButton: React.FC<FilterButton> = ({
  jobType,
  current,
  setCurrent,
  lang,
}) => {
  const onClick = () => setCurrent(jobType)
  return (
    <button
      className={`mx-1 mb-1 text-base leading-tight tracking-normal font-sans border-2 border-vp-darkblue px-2 py-1 rounded-lg hover:bg-vp-darkblue ${jobType ===
        current && "bg-vp-darkblue"}`}
      onClick={onClick}
    >
      {translateJobType(jobType, lang)}
    </button>
  )
}

interface JobFilter {
  onJobType: (jobType: JobType) => void
  lang: Languages
}

export const JobFilter: React.FC<JobFilter> = ({ onJobType, lang }) => {
  const [current, setCurrent] = useState<JobType>(JobType.All)

  useEffect(() => {
    onJobType && onJobType(current)
  }, [current])

  return (
    <div className="text-gray-100">
      <FilterButton
        jobType={JobType.All}
        current={current}
        setCurrent={setCurrent}
        lang={lang}
      />
      <FilterButton
        jobType={JobType.ConsultingFirms}
        current={current}
        setCurrent={setCurrent}
        lang={lang}
      />
      <FilterButton
        jobType={JobType.FinancialInstitutions}
        current={current}
        setCurrent={setCurrent}
        lang={lang}
      />
      <FilterButton
        jobType={JobType.CorporateBrands}
        current={current}
        setCurrent={setCurrent}
        lang={lang}
      />
    </div>
  )
}
