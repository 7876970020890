import React, { useState, useEffect, useRef } from "react"
import { Section, BG } from "../atoms/Section"

import { useStaticQuery, graphql } from "gatsby"
import { AnnouncementsCard, RecentPositionsCard } from "../atoms/Cards"
import { Title } from "../atoms/Title"
import { useDimensions } from "../hooks/useDimensions"
import { chunk } from "../../utils"
import { CarouselWrapper } from "../atoms/Carousel"
import Carousel, { ResponsiveType } from "react-multi-carousel"
import {
  WIDTH_BREAKPOINT,
  makeNewsRelease,
  makeNotification,
} from "../../constants"
import { useLang } from "../Header/LanguageToggle/useLang"
import { filterLang, JobFilter, filterJobType } from "./Filters"
import { Job, JobType } from "./types"
import { Languages } from "../../provider"

const RecentPositions = () => {
  const data = useStaticQuery(graphql`
    query BannersAndRecentPositionsQuery {
      banners: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          fileRelativePath: { eq: "/content/banners/notifications.md" }
          parent: {}
        }
      ) {
        edges {
          node {
            frontmatter {
              notificationTitle
              notificationDescription
              showNotification
              releaseTitle
              releaseDescription
              date
            }
            ...TinaRemark
          }
        }
      }
      positions: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          fileRelativePath: { regex: "/content/positions/" }
          parent: {}
        }
      ) {
        edges {
          node {
            frontmatter {
              date
              description
              title
              location
              filterType
              lang
            }
            fields {
              slug
            }
            ...TinaRemark
          }
        }
      }
    }
  `)

  const notifications = data.banners.edges?.[0]?.node?.frontmatter

  const lang = useLang()
  const jobs: Job[] = data.positions.edges
  const jobsFilteredByLang = jobs.filter(job => filterLang(job, lang))

  const [filteredJobs, setFilteredJobs] = useState(jobsFilteredByLang)
  const [jobType, setJobType] = useState(JobType.All)
  const carouselRef = useRef<Carousel>(null)
  useEffect(() => {
    const jobsFilteredByType = jobsFilteredByLang.filter(job =>
      filterJobType(job, jobType)
    )
    setFilteredJobs(jobsFilteredByType)
    carouselRef && carouselRef.current?.goToSlide(0)
  }, [jobType, lang])

  const { width } = useDimensions()
  const isNormal = width < 1600
  const isMobile = width < WIDTH_BREAKPOINT
  const isTiny = width < 400

  let gridTemplateColumns: string
  if (isTiny) {
    gridTemplateColumns = "100%"
  } else if (isMobile) {
    gridTemplateColumns = "100%"
  } else if (isNormal) {
    gridTemplateColumns = "1fr 1fr 1fr 1fr"
  } else {
    gridTemplateColumns = "1fr 1fr 1fr 1fr 1fr"
  }

  const desktop = (
    <span className="text-gray-100">
      Recent <br /> positions
    </span>
  )

  const mobile = <span className="text-gray-100">Recent positions</span>

  let numberOfCards: number
  if (isMobile) {
    numberOfCards = 5
  } else if (isNormal) {
    numberOfCards = 8
  } else {
    numberOfCards = 10
  }

  const titleClass =
    lang === Languages.JP ? "leading-8 text-2xl" : "leading-none text-3xl"

  const notificationBanner = notifications.showNotification
    ? makeNotification({
        title: notifications.notificationTitle,
        description: notifications.notificationDescription,
      })
    : null
  const releaseBanner = makeNewsRelease({
    title: notifications.releaseTitle,
    description: notifications.releaseDescription,
  })

  const banners = [notificationBanner, releaseBanner].filter(Boolean)
  const jobsAndAnnouncements = [...banners, ...filteredJobs].filter(Boolean)

  const jobGroups = chunk(jobsAndAnnouncements, numberOfCards) // groups of 8 items each
  const results = jobGroups.map((jobGroup: Job[], i: number) => {
    // for every 8 items, create jsx

    return (
      <div
        key={`recent-positions-page-${i}`}
        className="w-full p-4 overflow-visible"
        style={{
          display: "grid",
          gridTemplateColumns: gridTemplateColumns,
          gridColumnGap: 25,
          gridRowGap: isMobile ? 10 : 25,
        }}
      >
        {jobGroup.map((job: Job, j: number) => {
          // notificaitons
          if (job.type === "notifications") {
            return (
              <AnnouncementsCard
                fadeIn
                slug=""
                titleClass={titleClass}
                mobile={isMobile}
                key="notifications-0"
                title={job.node.frontmatter.title}
                description={job.node.frontmatter.description}
              />
            )
          }
          // announcements
          if (job.type === "announcements") {
            const slug =
              lang === Languages.EN
                ? job.node.fields.slug
                : job.node.fields.slugJp
            return (
              <AnnouncementsCard
                fadeIn
                slug={slug}
                titleClass={titleClass}
                mobile={isMobile}
                key="announcements-0"
                title={job.node.frontmatter.title}
                description={job.node.frontmatter.description}
              />
            )
          }

          // recent position jobs
          const data = job.node.frontmatter
          const slug = job.node.fields.slug

          return (
            <RecentPositionsCard
              fadeIn
              key={`job-${i}-${j}-${data.title}`}
              title={data.title}
              titleClass={titleClass}
              description={data.description}
              // date={data.date}
              type={data.filterType}
              location={data.location}
              slug={slug}
              mobile={isMobile}
            />
          )
        })}
      </div>
    )
  })

  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <Section bg={BG.blue} classes="overflow-visible">
      <div className="flex flex-col sm:pt-8 sm:items-end sm:flex-row">
        <Title>{isMobile ? mobile : desktop}</Title>
        <div className="mt-4 sm:ml-6">
          <JobFilter onJobType={setJobType} lang={lang} />
        </div>
      </div>

      <div
        className="mt-2 mb-32 -m-4 overflow-visible sm:mt-16"
        style={{ width: `calc(100% + 2rem)` }}
      >
        <CarouselWrapper
          responsiveBreakpoints={responsive}
          carouselRef={carouselRef}
        >
          {results}
        </CarouselWrapper>
        {/* {Jobs.map((job: Job, i: number) => {
          const data = job.node.frontmatter
          const slug = job.node.fields.slug

          return (
            <RecentPositionsCard
              fadeIn
              key={i}
              title={data.title}
              description={data.description}
              // date={data.date}
              type={data.jobFunction}
              location={data.location}
              slug={slug}
            />
          )
        })} */}
      </div>
    </Section>
  )
}

export default RecentPositions
