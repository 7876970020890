import React from "react"
import { Section } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import { TextContent } from "../../../components/atoms/TextContent"
import {
  ListCard,
  TripleColumnContainer,
} from "../../../components/atoms/Cards"
import { useIsMobile } from "../../../components/hooks/useDimensions"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
// import { externalLinks } from "../../../constants"

const TopPageContent = {
  [Languages.EN]:
    "Since 2012, we have been matching professionals with organizations across all levels of experience and seniority within three core areas. Our record of introducing dynamic and driven candidates has enabled us to win the trust of many core and new clients alike. ",
  [Languages.JP]: (
    <span>
      私たちは、<span className="text-2xl tracking-wider">2012</span>
      年の創業以来、様々なレベルの優秀な人材と企業をマッチングしてきました。質の高い候補者様をご紹介することによって、採用ご担当者様・候補者様より高い評価を頂いております。
    </span>
  ),
}

export const WhoWeWorkWithText = {
  [Languages.EN]: {
    consultingFirms: {
      title: "Consulting Firms",
      contentList: [
        "Strategy firms / Boutique / Specialized firms",
        "Big4",
        "Information technology / Digital ",
        "Advertising / HR",
        "Law firms",
      ],
    },
    financialInstitutions: {
      title: "Financial Institutions",
      contentList: [
        "Investment banks / Securities / Commercial and retail banks",
        "Private equity / Venture capital",
        "Asset management / Hedge funds/ Pension funds",
        "Real estate / Insurance",
      ],
    },
    corporateBrands: {
      title: "Corporate Brands",
      contentList: [
        "Food & beverage / FMCG / Manufacturing  ",
        "Luxury / Retail",
        "Pharmaceutical / Medical Device / Healthcare",
        "IT",
        "Pre IPO, Mid cap & High growth startups",
      ],
    },
    // digitalStartups: {
    //   title: "DIGITAL/STARTUPS",
    //   contentList: [
    //     "Venture capital",
    //     "AI/IoT/Blockchain",
    //     "Cyber security",
    //     "Engineer",
    //   ],
    //   link: {
    //     label: "Learn more",
    //     url: externalLinks.vpDigital,
    //   },
    // },
  },
  [Languages.JP]: {
    consultingFirms: {
      title: "プロフェッショナルサービス",
      contentList: [
        "戦略コンサルティングファーム・総合・ブティック系など",
        "Big4",
        "IT・DX",
        "広告・組織人事コンサルティングファーム",
        "法律事務所",
      ],
    },
    financialInstitutions: {
      title: "金融機関",
      contentList: [
        "投資銀行、証券会社、銀行",
        "投資ファンド、ベンチャーキャピタル",
        "アセットマネジメント、ヘッジファンド、年金運用ファンド",
        "不動産",
        "保険会社",
      ],
    },
    corporateBrands: {
      title: "事業会社",
      contentList: [
        "食品・飲料、FMCG、製造業",
        "ラグジュアリー、リテール",
        "医薬品、医療機器、ヘルスケア",
        "IT",
        "IPO予定企業、急成長のスタートアップなど",
      ],
    },
    // digitalStartups: {
    //   title: "デジタル/スタートアップ",
    //   contentList: [
    //     "ベンチャーキャピタル",
    //     "AI",
    //     "IoT",
    //     "ブロックチェーン",
    //     "サイバーセキュリティ",
    //     "エンジニア",
    //   ],
    //   link: {
    //     label: "Learn more",
    //     url: externalLinks.vpDigital,
    //   },
    // },
  },
}

const WhoWeWorkWith = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  const desktop = (
    <span>
      Who we <br /> work with
    </span>
  )

  const mobile = <span>Who we work with</span>

  const columnText = WhoWeWorkWithText[lang]
  const content = TopPageContent[lang]

  return (
    <Section classes="px-8">
      <div className="flex flex-col justify-between pl-2 -ml-2 bg-white sm:flex-row sm:-mb-16">
        <Title>{isMobile ? mobile : desktop}</Title>
        <div
          className="pt-4 pb-6 sm:pt-0 sm:px-6 sm:pb-12"
          style={{ width: isMobile ? "100%" : 944 }}
        >
          <TextContent
            extraClasses="max-w-2xl leading-tight sm:leading-snug"
            content={content}
          />
          <br className="h-8" />
          <TripleColumnContainer>
            <ListCard
              title={columnText.consultingFirms.title}
              contentList={columnText.consultingFirms.contentList}
            />
            <ListCard
              title={columnText.financialInstitutions.title}
              contentList={columnText.financialInstitutions.contentList}
            />
            <ListCard
              title={columnText.corporateBrands.title}
              contentList={columnText.corporateBrands.contentList}
            />
            {/* <ListCard
              title={columnText.digitalStartups.title}
              contentList={columnText.digitalStartups.contentList}
              link={{
                label: columnText.digitalStartups.link.label,
                url: columnText.digitalStartups.link.url,
              }}
            /> */}
          </TripleColumnContainer>
        </div>
      </div>
    </Section>
  )
}

export default WhoWeWorkWith
