import React from "react"
import Talents from "../pageComponents/Talents"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."

const TalentsPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Job Seekers" />
      <Talents />
    </Layout>
  )
}

export default TalentsPage
