import React, { useState } from "react"
import { Section, BG } from "../atoms/Section"
import { FAQCard } from "../atoms/Cards"
import { Title } from "../atoms/Title"
import { useIsMobile } from "../hooks/useDimensions"
import { PlusIcon } from "../atoms/PlusIcon"
import { motion } from "framer-motion"
import { Languages } from "../../provider"
import { useLang } from "../Header/LanguageToggle/useLang"

interface FAQText {
  question: string | JSX.Element
  answer: string | JSX.Element
}

const Text: { [key in Languages]: FAQText[] } = {
  [Languages.EN]: [
    {
      question:
        "I am not actively looking for a new role right now, is it still okay to request a counselling session?",
      answer:
        "Absolutely! Please do not hesitate to register or simply contact us for any questions, we are always happy to have an information exchange session. We are in the business of creating long term, win-win relationships.",
    },
    {
      question:
        "Do you have positions for firms outside of Japan or global branches of Japanese firms?",
      answer:
        "We continue to have roles in Hong Kong, South East Asia, Greater China, India, Africa and Brazil.",
    },
    {
      question:
        "Is it possible to request a Japanese person as I feel more comfortable speaking in Japanese? ",
      answer:
        "Definitely. We have several native Japanese speakers, as well as fully bilingual foreigners.",
    },
    {
      question: "I am currently living abroad. Can I register?",
      answer:
        "Absolutely. We have assisted many candidates living overseas transition back to Japan, China and SEA.",
    },
    {
      question: "How long does the recruiting process usually take?",
      answer:
        "It really depends on the organization you are applying to, the role and urgency of the hire. Typically the process requires 1-2 months to complete.",
    },
    {
      question: "What are some advantages of moving into consulting?",
      answer:
        "Think of consulting as a corporate MBA—but you don’t have to pay tuition! Consulting can help elevate your career, earning potential and put you on an executive leadership path. It is the ultimate career accelerator.",
    },
    {
      question:
        "What can I expect for my future career if I join a consulting firm?",
      answer:
        "Hard work, excellent learning environment, interesting topics that constantly change with the economy and a team-oriented approach to solving problems.",
    },
    {
      question: "What is compensation like in a consulting firm?",
      answer:
        "Consulting is second only to finance in compensation with experienced Partners making over $1M USD a year. A challenging but financially rewarding industry that looks for the best of the best. ",
    },
    {
      question: "What type of professional usually works at a consulting firm?",
      answer:
        "People that come from top 10-15 ranked universities in their home country, problem solvers, performance-driven individuals, team players, effective communicators, passionate about client services but most importantly—professionals who strive to become future leaders and entrepreneurs.",
    },
    {
      question: "How important is an MBA to joining a consulting firm?",
      answer:
        "Not a requirement but very helpful if entering strategic consulting firms.",
    },
    {
      question: "What can I learn from working in consulting?",
      answer:
        "You will learn the process involved in solving critical issues facing major companies, help businesses enter and thrive in new markets and learn effective communication skills with senior managers.",
    },
    {
      question: 'What are the differences between "strategy" firms and Big4?',
      answer:
        "Strategy firms are best known for covering CxO agenda while BIG 4 are immersed in specialized functionalities like Audit, Tax, M&A, valuation, SCM, IT, Due Diligence and BPR/BPO.",
    },
  ],
  [Languages.JP]: [
    {
      question: "直近の転職を考えてはいないが、登録や相談は可能ですか？",
      answer:
        "勿論ご登録頂けます。雇用状況や各ファームの動きなど情報交換をご希望の方もまずはお気軽にご相談下さい。",
    },
    {
      question:
        "海外ファームもしくは日系企業の海外オフィスの案件はありますか？",
      answer:
        "海外ベースの提携エグゼクティブサーチファームを通じ、随時海外案件もご紹介しております。",
    },
    {
      question: "英語以外での面談は可能ですか？",
      answer:
        "ご希望に応じて、日本人のコンサルタントや日本語の堪能なコンサルタントをご指名いただけます。",
    },
    {
      question: "海外在住ですが登録はできますか？",
      answer:
        "ご登録頂けます。お電話にてご希望やご経験を伺い、帰国に即してフィットするポジションをご紹介します。",
    },
    {
      question: "選考の期間は一般的にどのくらいかかりますか？",
      answer:
        "ケースバイケースとなりますが、早い方で１ヶ月、長い方で１年を要します。また短期ではなく中期、長期的なキャリア戦略のご相談も承ります。",
    },
    {
      question: "履歴書や面接対策のサポートはありますか？",
      answer:
        "履歴書の添削、面接に応じて必要なケーススタディ練習などフォローしております。",
    },
    {
      question:
        "バンテージポイントに登録して紹介してもらうことには、どんなメリットがあるのですか？",
      answer:
        "バンテージポイントは、クライアント企業様のマネージメントと信頼関係を構築し、その関係性を皆様に提供出来るよう尽力しています。弊社のコンサルタントは10年以上の人材紹介実績がある者、外資系金融、財務アドバイザリーファーム等のプロフェッショナルファーム出身が多く、的確なクライアント企業様のニーズ把握と候補者様のスキルとの最適なマッチングを提供します。",
    },
    {
      question: "担当のコンサルタントはどのように決まるのですか？",
      answer:
        "ご登録者の方の経歴や希望分野に合わせて、最も価値のあるサービスを提供できるであろうコンサルタントが担当させていただきます。またご希望があればご指名頂くことも可能です。",
    },
  ],
}

const PlusToggle: React.FC<{ onClick: () => void; minus: boolean }> = ({
  onClick,
  minus,
}) => {
  const [hovered, setHovered] = useState(false)
  const trigger = () => onClick && onClick()
  const onHoverStart = () => setHovered(true)
  const onHoverEnd = () => setHovered(false)
  return (
    <motion.div
      onClick={trigger}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
    >
      <PlusIcon width={40} invertColor hovered={hovered} minus={minus} />
    </motion.div>
  )
}

export const FAQ = () => {
  const lang = useLang()
  const text =
    lang === Languages.EN
      ? Text[lang]
      : Text[lang].map(data => ({
          ...data,
          question: <small>{data.question}</small>,
        }))

  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(p => !p)
  }

  const variants = {
    open: {
      height: "fit-content",
      opacity: 1,
    },
    closed: {
      height: 0,
      opacity: 0,
    },
  }
  return (
    <Section bg={BG.gray}>
      <div className="flex justify-between group">
        <Title content="FAQ" />{" "}
        {isMobile && <PlusToggle onClick={toggleOpen} minus={open} />}
      </div>
      <motion.div
        className="mt-8 mb-8 pointer-events-none sm:mb-24"
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "100%" : "1fr 1fr 1fr",
          gridGap: "2rem",
        }}
        variants={variants}
        animate={isMobile && !open ? "closed" : "open"}
      >
        {text.map((data, i) => (
          <FAQCard key={i} question={data.question} answer={data.answer} />
        ))}
      </motion.div>
    </Section>
  )
}
