export interface Job {
  node: {
    frontmatter: {
      date: string
      description: string
      title: string
      location: string
      filterType: string
      lang: string
      notificationTitle?: string
      notificationDescription?: string
      showNotification?: boolean
      releaseTitle?: string
      releaseDescription?: string
    }
    fields: {
      slug: any
      slugJp?: any
    }
  }
  type?: any
}

export enum JobType {
  All = "All",
  ConsultingFirms = "Consulting Firms",
  FinancialInstitutions = "Financial Institutions",
  CorporateBrands = "Corporate Brands",
}

export const jobTypeENJPMapper = {
  [JobType.All]: "すべて",
  [JobType.ConsultingFirms]: "プロフェッショナルサービス",
  [JobType.FinancialInstitutions]: "金融機関",
  [JobType.CorporateBrands]: "事業会社",
}
