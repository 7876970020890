import React from "react"
import { TitleH4 } from "../atoms/Title"
import { RightMoveCard } from "../atoms/Cards"
import { CarouselWrapper } from "../atoms/Carousel"
import { ResponsiveType } from "react-multi-carousel"
import { Section, BG } from "../atoms/Section"
import { Languages } from "../../provider"
import { useLang } from "../Header/LanguageToggle/useLang"

interface RightMove {}

interface RightMoveText {
  fromCompany: string
  toCompany: string
  placing?: string
  role?: string
  age: number
  description: string
  to?: string
  compensationIncrease?: number
}

type TextContent = { [key in Languages]: { contentList: RightMoveText[] } }

const OldText: TextContent = {
  [Languages.EN]: {
    contentList: [
      {
        fromCompany: "Major Japanese Utility Provider",
        toCompany: "Management Consulting Firm",
        placing: "Associate, major strategy firm",
        role: "Partner",
        age: 40,
        description:
          "Identified a candidate that spent his entire prior career with a major Japanese utility and introduced him to a strategic consulting firm. The candidate recently became a Partner in his new firm.",
        to: "",
      },
      {
        fromCompany: "Corporate",
        toCompany: "Consulting Firm",
        placing: "BIG4",
        role: "Senior Associate",
        age: 34,
        description:
          "A candidate coming from a major multinational high tech's SCM team, wanted to take a new challenge. We effectively placed him into a BIG4  within one month from the start of the process.",
        to: "",
      },
      {
        fromCompany: "BIG4 Consulting Firm",
        toCompany: "Strategy Consulting Firm",
        placing: "BIG4",
        role: "Engagement Manager",
        age: 30,
        description:
          "This candidate started his career in audit, transitioned to restructuring but eventually wanted a role in management consulting. We were able to identify his transferable skill-set to strategy and help coach him throughout the process. Since joining he has been promoted to Project Leader and continues to do well.",
        to: "",
      },
      {
        fromCompany: "Mega Bank",
        toCompany: "BIG4 Consulting Firm",
        placing: "BIG4",
        role: "M&A valuation services",
        age: 38,
        description:
          "Worked successfully with a Vice President from a Japanese Mega Bank to join a BIG4 where he entered the firm as a Director, assigned to build and lead a new team with a significant 22% salary increase.",
        to: "",
      },
      {
        fromCompany: "Japanese Consumer Company",
        toCompany: "Strategy Consulting Firm",
        placing: "Japanese FMCG",
        role: "Director",
        age: 37,
        description:
          "This candidate was introduced to us through another happy professional we previously worked with. She had spent most of her career with a top management consulting firm and wanted a new challenge with a global Japanese brand. Our team helped her successfully transition and she was the first female Director the company had hired from outside.",
        to: "",
      },
    ],
  },
  [Languages.JP]: {
    contentList: [
      {
        fromCompany: "日系メーカー",
        toCompany: "戦略コンサルティング ",
        placing: "アジアパシフィックチーム",
        role: "経営戦略部門長",
        age: 39,
        description:
          "候補者様は、大手戦略コンサルティングファームにてプロジェクトリーダーを経た後、事業会社でのポジションでの転職支援を他の人材紹介会社に依頼していたものの、1年近く経過してもなかなか良いポジションが見つからない状態でした。弊社にコンタクトいただいた後、上場企業の経営戦略部のトップのポジションをご紹介したところ転職が決定。",
        to: "",
      },
      {
        fromCompany: "日系メーカー",
        toCompany: "戦コン",
        placing: "アジアパシフィックチーム",
        role: "経営戦略部門長",
        age: 39,
        description:
          "候補者様は、大手戦略コンサルティングファームにてプロジェクトリーダーを経た後、事業会社でのポジションを他の人材紹介会社に依頼していたものの、1年ほど経ってもなかなか良いポジションが見つからず。弊社にコンタクトいただいた後、すぐに上場企業の経営戦略部のトップのポジションをご紹介し、ご就任。",
        to: "",
      },
      {
        fromCompany: "投資銀行",
        toCompany: "PEファンド",
        role: "バイスプレジデント",
        age: 42,
        description:
          "弊社を通してキャリアチェンジをされた別の候補者様のご紹介から、投資銀行勤務の候補者様を大手日系PEファンドのポートフォリオマネージメントポジションにご紹介。",
        to: "",
      },
      {
        fromCompany: "エネルギー",
        toCompany: "コンサル",
        role: "アソシエイトパートナー",
        age: 36,
        description:
          "電力・ガス勤務に一貫して勤務されていた候補者様を戦略コンサルティングファームの同業種チームにご紹介。アソシエイトとして転職後、順調にプロモーションを重ね、現在は担当ユニットのアソシエイトパートナーとしてご活躍。給与面では転職前に比べて倍額へのステップアップを実現。",
        to: "",
      },
      {
        fromCompany: "投資銀行",
        toCompany: "FAS",
        role: "エクイティアナリスト",
        age: 27,
        description:
          "BIG4ファイナンシャルアドバイザリーにてシニアソシエイト職に就かれていた候補者様を、より本人のご希望に近い米系投資銀行フロントオフィスワークにご紹介。",
        to: "",
      },
      {
        fromCompany: "メガバンク",
        toCompany: "FAS",
        placing: "M&A、バリュエーションチーム",
        role: "シニアアソシエイト",
        age: 28,
        description:
          "候補者様へのポジションのご紹介から面接対策、入社準備を全面的にサポートし、BIG4ファイナンシャルアドバイザリーに入社。 その後、候補者様は順調にプロモーションを達成。",
        to: "",
      },
    ],
  },
}

const Text: TextContent = {
  [Languages.EN]: {
    contentList: [
      {
        fromCompany: "Strategy Consulting Firm",
        toCompany: "Start-up tech company",
        placing: "Country Manager",
        age: 39,
        description:
          "This candidate was a successful Principal in a major strategy consulting firm and we helped him transition to a very exciting start-up that was entering the Japanese market as the Country Manager.",
      },
      {
        fromCompany: "BIG4",
        toCompany: "Japanese Mega Pharma",
        placing: "Global Head of SEC reporting",
        age: 37,
        description:
          "Our client was looking for a very rare candidate, someone who could lead their global SEC reporting. This candidate had spent his entire career in BIG4, was a very unique talent that was a perfect fit for our client. It was a great opportunity for the candidate as well to take the lead role in a critical corporate function.",
      },
      {
        fromCompany: "Strategy Consulting Firm",
        toCompany: "Strategy Consulting Firm",
        placing: "Partner, Digital",
        age: 44,
        description:
          "Our client had been looking for a senior leader in their digital practice for over a year and actually approached this candidate directly in the past but it wasn’t until VP engaged were they able to gain the candidates acceptance to join the firm.",
      },
      {
        fromCompany: "Major Japanese Utility Provider",
        toCompany: "Management Consulting Firm",
        placing: "Associate",
        role: "Partner",
        age: 44,
        description:
          "Identified a candidate that spent his entire career with a major Japanese utility and introduced him to a strategic consulting firm. The candidate recently became a Partner in his new firm and is thriving.",
      },
      {
        fromCompany: "Corporate",
        toCompany: "Consulting Firm",
        placing: "Senior Associate",
        role: "Director",
        age: 41,
        compensationIncrease: 110,
        description:
          "A candidate coming from a domestic blue chip's SCM team, wanted to take a new challenge. We effectively placed him into a BIG4 within one month from the start of the process and has promoted very quickly over the past 6 years to become a Director.",
      },
      {
        fromCompany: "BIG4 Consulting Firm",
        toCompany: "Strategy Consulting Firm",
        placing: "Engagement Manager",
        age: 30,
        compensationIncrease: 31,
        description:
          "This candidate started his career in audit, transitioned to restructuring but eventually wanted a role in management consulting. We were able to identify his transferable skill-set to strategy and help coach him throughout the process. Since joining he has been promoted to Project Leader and continues to do well.",
      },
      {
        fromCompany: "Mega Bank",
        toCompany: "BIG4 Consulting Firm",
        placing: "M&A and valuation services",
        age: 38,
        compensationIncrease: 22,
        description:
          "Worked successfully with a Vice President from a Japanese Mega Bank to join a BIG4 where he entered the firm as a Director, assigned to build and lead a new team with a significant 22% salary increase. ",
      },
      {
        fromCompany: "Strategy Consulting Firm",
        toCompany: "Japanese Consumer Company",
        placing: "Director",
        age: 37,
        description:
          "This candidate was introduced to us through another happy professional we previously worked with. She had spent most of her career with a top management consulting firm and wanted a new challenge with a global Japanese brand. Our team helped her successfully transition and she was the first female Director the company hired from outside.",
      },
    ],
  },
  [Languages.JP]: {
    contentList: [
      {
        fromCompany: "日系メーカー",
        toCompany: "戦略コンサルティング ",
        placing: "アジアパシフィックチーム",
        role: "経営戦略部門長",
        age: 39,
        description:
          "候補者様は、大手戦略コンサルティングファームにてプロジェクトリーダーを経た後、事業会社ポジションでの転職支援を他の人材紹介会社に依頼していたものの、1年近く経過してもなかなか良いポジションが見つからない状態でした。弊社にコンタクトいただいた後、上場企業の経営戦略部のトップのポジションをご紹介したところ転職が決定。 ",
        to: "",
      },
      {
        fromCompany: "日系メーカー",
        toCompany: "戦コン",
        placing: "アジアパシフィックチーム",
        role: "経営戦略部門長",
        age: 39,
        description:
          "候補者様は、大手戦略コンサルティングファームにてプロジェクトリーダーを経た後、事業会社でのポジションを他の人材紹介会社に依頼していたものの、1年ほど経ってもなかなか良いポジションが見つからず。弊社にコンタクトいただいた後、すぐに上場企業の経営戦略部のトップのポジションをご紹介し、ご就任。",
        to: "",
      },
      {
        fromCompany: "投資銀行",
        toCompany: "PEファンド",
        role: "バイスプレジデント",
        age: 42,
        description:
          "弊社を通してキャリアチェンジをされた別の候補者様のご紹介から、投資銀行勤務の候補者様を大手日系PEファンドのポートフォリオマネージメントポジションにご紹介。",
        to: "",
      },
      {
        fromCompany: "エネルギー",
        toCompany: "コンサル",
        role: "アソシエイトパートナー",
        age: 36,
        description:
          "電力・ガス勤務に一貫して勤務されていた候補者様を戦略コンサルティングファームの同業種チームにご紹介。アソシエイトとして転職後、順調にプロモーションを重ね、現在は担当ユニットのアソシエイトパートナーとしてご活躍。給与面では転職前に比べて倍額へのステップアップを実現。",
        to: "",
      },
      {
        fromCompany: "投資銀行",
        toCompany: "FAS",
        role: "エクイティアナリスト",
        age: 27,
        description:
          "BIG4ファイナンシャルアドバイザリーにてシニアソシエイト職に就かれていた候補者様を、より本人のご希望に近い米系投資銀行フロントオフィスワークにご紹介。",
        to: "",
      },
      {
        fromCompany: "メガバンク",
        toCompany: "FAS",
        placing: "M&A、バリュエーションチーム",
        role: "シニアアソシエイト",
        age: 28,
        description:
          "候補者様へのポジションのご紹介から面接対策、入社準備を全面的にサポートし、BIG4ファイナンシャルアドバイザリーに入社。 その後、候補者様は順調にプロモーションを達成。",
        to: "",
      },
    ],
  },
}

export const RightMove = () => {
  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const lang = useLang()
  const text = Text[lang]

  return (
    <Section bg={BG.gray} fullHeight>
      <div className="px-6 pt-10 xs:px-10 sm:px-16 sm:pt-12">
        <div className="flex justify-center">
          <TitleH4 title="Candidates we helped make the right move" />
        </div>
      </div>
      <br className="h-4" />
      <div className="mb-12 -mx-4">
        {/* counteract p-4 below on carousel contents */}
        <CarouselWrapper
          responsiveBreakpoints={responsive}
          infinite
          // centerMode={dimensions.width > 1024}
          invertDotColor
        >
          {text.contentList.map((content, i) => {
            return (
              <div key={`right-move-${i}`} className="h-full px-4 pt-4 pb-6">
                <RightMoveCard {...content} />
              </div>
            )
          })}
        </CarouselWrapper>
      </div>
    </Section>
  )
}
