import React from "react"
import Hero from "./Hero"
import { CallToAction } from "../../components/CallToAction"
import ChangeThePattern from "./ChangeThePattern"
import RecentPositions from "../../components/RecentPositions"
import {
  WhyPeopleWorkWithUs,
  WhyPeopleContent,
} from "../../components/WhyPeopleWorkWithUs"
import { RightMove } from "../../components/RightMove"
import { HowItWorksTalents } from "../../components/HowItWorks"
import { FAQ } from "../../components/FAQ"
import { useLang } from "../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../provider"
import { EmailType } from "../../utils/sendEmail"
import HeroText from "./HeroText"

const CTATitle = (
  <span>
    Are you designing
    <br />
    your career?
  </span>
)

const CTAContent = {
  [Languages.EN]:
    "Even if you’re not actively looking for a new opportunity, please reach out to discuss different strategies and ask how we can assist you with your long-term career vision. ",
  [Languages.JP]: [
    <span className="text-xs sm:ml-6">
      現在積極的に転職をご検討されていない場合でも、将来的な可能性やビジョンの実現方法など、お気軽にご相談ください。
    </span>,
  ],
}

const Page = () => {
  const lang = useLang()
  return (
    <div>
      <Hero />
      <HeroText />
      <ChangeThePattern />
      <div className="-mb-12 sm:mb-0">
        <RecentPositions />
      </div>
      <WhyPeopleWorkWithUs type={WhyPeopleContent.jobSeekers} />
      <RightMove />
      <HowItWorksTalents />
      <div className="mt-16">
        <FAQ />
      </div>
      <CallToAction
        title={CTATitle}
        text={CTAContent[lang]}
        type={EmailType.default}
      />
    </div>
  )
}

export default Page
